import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));
const ProfilePage = Loadable(lazy(() => import('../views/profile')));
// User Routes
const UserListPage = Loadable(lazy(() => import('../views/user')));
const UserCreatePage = Loadable(lazy(() => import('../views/user/view')));

//Settings

const ReferralSettingPage = Loadable(lazy(() => import('../views/settings/referral/index')));

const TradingHouseSettingPage = Loadable(lazy(() => import('../views/trading-house-setting/index')));

const GameListPage = Loadable(lazy(() => import('../views/settings/game')));
const GameCreatePage = Loadable(lazy(() => import('../views/settings/game/create')));

const CountryListPage = Loadable(lazy(() => import('../views/settings/country')));
const CountryCreatePage = Loadable(lazy(() => import('../views/settings/country/create')));

const TradingHouseListPage = Loadable(lazy(() => import('../views/trading-house')));
const TradingHouseViewPage = Loadable(lazy(() => import('../views/trading-house/view')));

// Marketplace Category
const MarketPlaceCategoryListPage = Loadable(lazy(() => import('../views/marketplace/category')));
const CreateMarketPlaceCategoryPage = Loadable(lazy(() => import('../views/marketplace/category/create')));

// Marketplace Product
const MarketPlaceProductListPage = Loadable(lazy(() => import('../views/marketplace/product')));
const CreateMarketPlaceProductPage = Loadable(lazy(() => import('../views/marketplace/product/create')));

// Order
const OrderListPage = Loadable(lazy(() => import('../views/order')));
const ViewOrderPage = Loadable(lazy(() => import('../views/order/view')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard/default',
                '/utils/util-typography',
                '/utils/util-color',
                '/utils/util-shadow',
                '/icons/tabler-icons',
                '/icons/material-icons',
                '/sample-page',
                '/profile',
                '/user-list',
                '/user-create',
                '/user-view/:id',
                '/referral-settings',
                '/trading-house-settings',
                '/game-list',
                '/game-create',
                '/game-edit/:id',

                '/country-list',
                '/country-create',
                '/country-edit/:countryCode',
                '/trading-house-list',
                '/trading-house-view/:id',

                '/marketplace-category-list',
                '/marketplace-category-create',
                '/marketplace-category-edit/:id',

                '/marketplace-product-list',
                '/marketplace-product-create',
                '/marketplace-product-edit/:id',

                '/order-list',
                '/order-view/:id',
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/dashboard/default" component={DashboardDefault} />

                        <Route path="/utils/util-typography" component={UtilsTypography} />
                        <Route path="/utils/util-color" component={UtilsColor} />
                        <Route path="/utils/util-shadow" component={UtilsShadow} />
                        <Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
                        <Route path="/icons/material-icons" component={UtilsMaterialIcons} />

                        <Route path="/sample-page" component={SamplePage} />
                        <Route path="/profile" component={ProfilePage} />
                        <Route path="/user-list" component={UserListPage} />
                        <Route path="/user-create" component={UserCreatePage} />
                        <Route path="/user-view/:id" component={UserCreatePage} />

                        <Route path="/referral-settings" component={ReferralSettingPage} />

                        <Route path="/trading-house-settings" component={TradingHouseSettingPage} />

                        <Route path="/game-list" component={GameListPage} />
                        <Route path="/game-create" component={GameCreatePage} />
                        <Route path="/game-edit/:id" component={GameCreatePage} />

                        <Route path="/country-list" component={CountryListPage} />
                        <Route path="/country-create" component={CountryCreatePage} />
                        <Route path="/country-edit/:countryCode" component={CountryCreatePage} />

                        <Route path="/trading-house-list" component={TradingHouseListPage} />
                        
                        <Route path="/trading-house-view/:id" component={TradingHouseViewPage} />


                        <Route path="/marketplace-category-list" component={MarketPlaceCategoryListPage} />
                        <Route path="/marketplace-category-create" component={CreateMarketPlaceCategoryPage} />
                        <Route path="/marketplace-category-edit/:id" component={CreateMarketPlaceCategoryPage} />

                        <Route path="/marketplace-product-list" component={MarketPlaceProductListPage} />
                        <Route path="/marketplace-product-create" component={CreateMarketPlaceProductPage} />
                        <Route path="/marketplace-product-edit/:id" component={CreateMarketPlaceProductPage} />

                        <Route path="/order-list" component={OrderListPage} />
                        <Route path="/order-view/:id" component={ViewOrderPage} />
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
