import { dashboard } from './dashboard';
import { settings } from './settings';
import {marketplace} from './marketplace';

//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [
        dashboard,
        settings,
        marketplace,
    ]
};

export default menuItems;
