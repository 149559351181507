// assets
import { IconCategory, IconPackage, IconTruckDelivery } from '@tabler/icons';

// constant
const icons = {
    IconCategory: IconCategory,
    IconPackage: IconPackage,
    IconTruckDelivery: IconTruckDelivery,
};

export const marketplace = {
    id: 'marketplace',
    title: 'Marketplace',
    type: 'group',
    children: [
        {
            id: 'marketplace-category-list',
            title: 'Category',
            type: 'item',
            url: '/marketplace-category-list',
            icon: icons['IconCategory'],
            breadcrumbs: false
        },
        {
            id: 'marketplace-product-list',
            title: 'Products',
            type: 'item',
            url: '/marketplace-product-list',
            icon: icons['IconCategory'],
            breadcrumbs: false
        },
        {
            id: 'order-list',
            title: 'Orders',
            type: 'item',
            url: '/order-list',
            icon: icons['IconTruckDelivery'],
            breadcrumbs: false
        },
    ]
};
